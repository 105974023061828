<template>
  <div class="h-100">
    <!--<h1>Насосное оборудование АДЛ</h1>-->
    <v-row class="h-100 center_v" justify="center" align="center" no-gutters >
      <v-col md="12">
        <h1>Подбор <br>насосного оборудования АДЛ</h1>
      </v-col>
      <v-col xl="5" lg="9" md="11">
        <div class="button_container">
          <v-btn @click="$router.push('/granpump/')" variant="outlined" :size="!$vuetify.display.xs ? 'x-large' :'small'" class="mt-4" color="var(--root-color)">Насосы ГРАНПАМП</v-btn>
          <v-btn @click="$router.push('/granflow/')" variant="outlined"  :size="!$vuetify.display.xs ? 'x-large' :'small'"  class="mt-4" color="var(--root-color)">Насосные установки ГРАНФЛОУ</v-btn>
        </div>
        <!--<v-card>
          <v-card-item>
              <v-card-title><h1 class="text-center text-h4">Подбор насосного оборудования АДЛ</h1></v-card-title>
              <v-card-subtitle></v-card-subtitle>
            </v-card-item>
            <v-card-text>
              <v-btn @click="$router.push('/granpump/')" variant="tonal" :size="!$vuetify.display.xs ? 'large' :'small'" block color="var(--root-color)">Насосы ГРАНПАМП</v-btn>
              <v-btn @click="$router.push('/granflow/')"  variant="tonal" :size="!$vuetify.display.xs ? 'large' :'small'" block class="mt-4" color="var(--root-color)">Насосные установки ГРАНФЛОУ</v-btn>
            </v-card-text>
        </v-card>-->
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


// Components
//import HelloWorld from '../components/HelloWorld.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    //HelloWorld,
  },

  mounted() {
    //console.log(this.$vuetify.display.xs)
  },
});
</script>
<style scoped>
h1{
  color: #0c4da2;
  text-shadow: 0px 0px 5px #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
}
.center_v {
  align-items: center !important;
  align-content: center !important;
}
.button_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 25px 0px;
  flex-wrap: wrap;
}
.button_container > button{
  background-color: #fff;
  width: calc(50% - 15px);
  min-width: fit-content;
  max-width: 100%;

}
</style>